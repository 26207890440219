// @flow
import React from "react";
import { Container } from "components/styles/layout";
import { MainHeader } from "./header.styles";
import Symbol from "components/symbols/Symbol";
import { colors } from "components/styles/variables";

const Header = () => {
  return (
    <MainHeader>
      <Container>
        <Symbol
          id="logo-text"
          fill={colors.primary}
          maxWidth={130}
          maxHeight={40}
        />
      </Container>
    </MainHeader>
  );
};

export default Header;
