// @flow
import React from "react";
import Base from "./Base";

import Header from "./header/Header";
import Footer from "./footer/Footer";

type PropType = {
  children?: React$Node,
};

const PublicPage = (props: PropType) => (
  <Base
    header={<Header />}
    content={<>{props.children}</>}
    footer={<Footer />}
  />
);

export default PublicPage;
