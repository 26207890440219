// @flow
import styled from "styled-components";
import { spacing } from "components/styles/variables";

export const MainHeader = styled.header`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  padding: ${spacing.large}px 0;
`;
