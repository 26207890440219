// @flow
import React from "react";
import PublicPage from "components/templates/PublicPage";
// import styled from "styled-components";
import { H1, H6, Lead } from "components/styles/type";
import { colors } from "components/styles/variables";
import { Container, Row } from "components/styles/layout";

const NotFoundPage = () => (
  <PublicPage>
    <Container>
      <Row middle center style={{ minHeight: "100vh" }}>
        <div>
          <H6>404 Error</H6>
          <H1 style={{ color: colors.primary }}>Sorry, Page Not Found</H1>
          <Lead>It seems we either move this page or it doesn't exist. </Lead>
          <p>
            Please check your link again or{" "}
            <a href="mailto:support@hotfix.studio">contact us</a> for support.
          </p>
        </div>
      </Row>
    </Container>
  </PublicPage>
);

export default NotFoundPage;
