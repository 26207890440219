// @flow
import React from "react";
import { Container, Row, Col } from "components/styles/layout";
import Symbol from "components/symbols/Symbol";
// import { colors } from "components/styles/variables";
import { MainFooter, SocialMediaLink } from "./footer.styles";
import { Small } from "components/styles/type";
import { UtilDiv } from "components/styles/utils";

const Footer = () => {
  return (
    <MainFooter>
      <Container>
        <Row middle>
          <Col sm4>
            <Small marginNoneSm>An Identity & Development Studio</Small>
          </Col>
          <Col sm4>
            <Small marginNoneSm textCenterSm>
              © 2019 Hotfix Studio, LLC
            </Small>
          </Col>
          <Col sm4>
            <UtilDiv marginNone textRightSm>
              <SocialMediaLink href="https://www.instagram.com/hotfix.studio/">
                <Symbol maxWidth={18} maxHeight={18} id="instagram" />
              </SocialMediaLink>
              <SocialMediaLink href="https://www.pinterest.com/hotfixstudio/">
                <Symbol maxWidth={18} maxHeight={18} id="pinterest" />
              </SocialMediaLink>
              <SocialMediaLink href="https://www.facebook.com/hotfixstudio">
                <Symbol maxWidth={18} maxHeight={18} id="facebook" />
              </SocialMediaLink>
              <SocialMediaLink href="https://twitter.com/hotfixstudio">
                <Symbol maxWidth={18} maxHeight={18} id="twitter" />
              </SocialMediaLink>
            </UtilDiv>
          </Col>
        </Row>
      </Container>
    </MainFooter>
  );
};

export default Footer;
