// @flow
import styled from "styled-components";
import { spacing, colors } from "components/styles/variables";

export const MainFooter = styled.footer`
  background: ${colors.primary};
  color: ${colors.redLight};
  padding: ${spacing.small}px 0;
`;

export const SocialMediaLink = styled.a`
  display: inline-block;
  margin-right: ${spacing.xsmall}px;
  opacity: 0.5;

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
`;
